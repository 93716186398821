.footer{
  padding-top: 2em;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
  color: rgba(54, 54, 54, 1);

}
.footerToText {
  text-align: center;
  padding: 0vw 15vw 2vw 15vw;
}

.footerHeader {
  font-size: 2.5em;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.footerTopText {
  font-size: 1em;
}

.input-container {
  position: relative;
  display: inline-block;
}

.myInput {
  /* width: 25em;
  height: 3em; */
  border-radius: 4em;
  border: 0px solid #54514e;
  font-size: 1.5em;
  padding: 1em;
  z-index: 0;
}

.myInput::placeholder {
  font-size: 1em;
}
.myButton {
  position: absolute;
  border-radius: 4em;
  font-size: 1em;
  color: #fefefe;
  font-weight: 500;
  padding: 0vw 1vw 0vw 1vw;
  /* width: 9em;
  height: 3em; */
  background: rgba(158, 28, 49, 1);
  right: 0em;
  text-align: center;
  margin: auto;
  
}

/* @media (max-width: 780px) {
  .myButton {
    top: 3.5vw;
    transform: translateY(-100%);
  }
  .myInput {
    height: 3vw;
  }
}
@media (max-width: 734px) {
  .myButton {
    top: 3.8vw;
    transform: translateY(-100%);
  }
  .myInput {
    height: 3vw;
  }
}

@media (max-width: 670px) {
  .myButton {
    top: 4vw;
    transform: translateY(-100%);
  }
  .myInput {
    height: 3vw;
  }
}

@media (max-width: 620px) {
  .myButton {
    top: 4.1vw;
    transform: translateY(-100%);
  }
  .myInput {
    height: 3vw;
  }
}

@media (max-width: 580px) {
  .myButton {
    top: 4vw;
    transform: translateY(-90%);
  }
  .myInput {
    height: 3vw;
  }
}

@media (max-width: 548px) {
  .myButton {
    top: 4.1vw;
    transform: translateY(-90%);
  }
  .myInput {
    height: 3vw;
  }
}

@media (max-width: 530px) {
  .myButton {
    top: 4.4vw;
    transform: translateY(-90%);
    height: 4vw;
  }
  .myInput {
    height: 3vw;
  }
}

@media (max-width: 480px) {
  .myButton {
    top: 4.8vw;
    transform: translateY(-90%);
    height: 4vw;
  }
}

@media (max-width: 440px) {
  .myButton {
    top: 5.3vw;
    transform: translateY(-90%);
    height: 4vw;
  }
}

@media (max-width: 391px) {
  .myButton {
    top: 5.7vw;
    transform: translateY(-90%);
    height: 4vw;
  }
}
@media (max-width: 355px) {
  .myButton {
    top: 6.8vw;
    transform: translateY(-90%);
    height: 4.5vw;
  }
}

@media (max-width: 296px) {
  .myButton {
    top: 7.5vw;
    transform: translateY(-90%);
    height: 4.5vw;
  }
}
@media (max-width: 267px) {
  .myButton {
    top: 8.1vw;
    transform: translateY(-90%);
    height: 4.5vw;
  }
}
@media (max-width: 248px) {
  .myButton {
    top: 8.6vw;
    transform: translateY(-90%);
    height: 4.5vw;
  }
} */
.footerIcon {
  width: 4vw;
  height: 4vw;
}

.footerBottom {
  margin-bottom: 3vw;
}

.footerBottomTitleContainer {
  padding-left: 4.2vw;
  
}

.footerBottomTitle {
  font-size: 2em;
}
.footerBottomContent {
  font-size: 1.2em;
  margin-bottom: 0px;
}

.footerBottomIcon {
  font-size: 2em;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center; 
  justify-content: center; 
  border-radius: 50px;
  width: 3vw;
  height: 3vw;
}
.iconContaniner {
  margin-right: 4vw;
  height: 1em;
}
