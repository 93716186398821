.accordionHome-item {
    display: flex;
    border-radius: 1em;
    transition: width 0.5s ease-in-out, opacity 0.3s ease-in-out;
    width: 560px;
  }
  @media (max-width: 768px) {
    .accordionHome-item {
      display: flex;
      border-radius: 1em;
      transition: width 0.5s ease-in-out, opacity 0.3s ease-in-out;
      width: 360px;
    } 
    .small{
      width: 360px;
    }
    .mid{
      width: 360px ;
    }
  }
  
  .small{
    width: 12vw;
  }
  .mid{
    width: 22vw ;
  }
  .accordionHome-item:hover{
    width: 52vw;
  }
  .accordionHome-item.active{
    width: 1100px;
  }
  .accordionHome-item:hover .image-container{
    display: none;
  }
  .image-container {
    position: relative;
    cursor: pointer;
    
    transition: background-color 0.5s;
    object-fit: cover;
    height: 83vh;
    
  }
  
  .image {
    max-width: 100%;
    max-height: 100%; 
    object-fit: cover; 
  }
  
  .image-overlayHome {
    display: flex;
    align-items: last baseline;
    justify-content: start;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    color: white;
    font-size: 1.5em;
    padding: 1em;
  }
  .overlay-text{
    font-size: 1.5em;
  }
  .overlay-textSmall{
    font-size: 1em;
  }
 
  .accordionHome-content {
    display: none;
    background-color:rgba(252, 248, 240, 1);
    overflow-y:auto;
    overflow-x: hidden;
    height: 83vh;
    opacity: 0;
    /* transition: opacity 0.5s ease-in-out; */
  }
   
  .accordionHome-item:hover .accordionHome-content {
    display: block;
    background-color: rgba(252, 248, 240, 1);
    opacity: 1;
    /* animation: fadeIn 0.5s ease-in-out, expand 0.5s ease-in-out; */
}
.accordionHome-content.inactive {
    animation: fadeOut 0.5s ease-in-out, expand 0.5s ease-in-out;
  }
  .accordionHome-item:hover .image-container {
    height: 80vh;
    animation: fadeOut 0.5s ease-in-out, collapseContent 0.5s ease-in-out;
} 
  .hide{
    display: none;
    opacity: 0;
  }
  .show{
    display: block;
  }
  .projectContainerHome{
    padding: 2.5em;
    height: 100%;
    
  }
  .projectImgContainer{
    height: 100%;
  }
  .projectDescription{
    line-height: 1.5em;
    text-align: justify;
  }
  .titleSubPro {
    display: inline;
    font-size: 2vw;
    font-family: "Arial";
    font-weight: 500;
  }
  .redBoldSubPro {
  background-color: brown;
  width: 0.7vw;
  height: 0.7vw;
  border-radius: 50px;
  margin-left: 0.4vw;
  margin-bottom: -0.2vw;
}
.primaryContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 1.5em;
}
.expandBtn{
    height: 2em;
    width: 2em;
    z-index: 10;
}  
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(17, 17, 17, 0.8);
    padding: 2em;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
}
.card-bg{
    object-fit: cover;
    height: 100%;
    position: initial;
    z-index: 0;
}

.title1HomeAcc,
.title2HomeAcc {
  display: inline;
  font-size: 3.5vw;
  font-weight: 700;
  font-family: "Arial";
  line-height: 1.25em;
}
.redBoldHomeAcc {
    background-color: brown;
    width: 1.0vw;
    height: 1.0vw;
    border-radius: 50px;
    margin-left: 7.4vw;
    margin-bottom: -0.5vw;
}

.transparentBoldHomeAcc {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50px;
    margin-bottom: -1vw;
}
.collabImgContainer{
    position: relative;
    z-index: 0;
}
.collabImg{
    position: absolute;

}
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes expand {
    from {
      width: 0;
    }
    to {
      width: 50vw;
    }
  }
  @keyframes collapseContent {
    from {
      width: 50;
    }
    to {
      width: 100%;
    }
  } 

  @media (max-width: 768px) {
    .accordionHome-item:hover{
      width: 90vw;
    }
    .accordionHome-item.active{
      width: 90vw;
    }
  }