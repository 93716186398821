/* Accordion styles */
 
  .accordion-item {
    transition: width 0.5s ease-in-out, opacity 0.3s ease-in-out;
   
  }
  
  .accordion-item.active {
    background-color: #f0f0f0;
    /* width: 80vw; */
    height: fit-content;
    
  }
  
  .image-containerPro {
    position: relative;
    cursor: pointer;
    transition: background-color 0.5s;
    object-fit: cover;
    /* height: 70vh; */
    
  }
  
  .image {
    max-width: 100%;
    max-height: 100%; 
    object-fit: cover; 
  }
  
  .image-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    color: white;
    transition: opacity 0.5s;
    font-size: 1.5em;
    text-align: center;
  }
  .overlay-text{
    font-size: 1.5em;
  }
  .overlay-textSmall{
    font-size: 1em;
  }
  .image-containerPro:hover .image-overlay {
    display: flex; /* Show the overlay on hover */
    opacity: 1;
  }
  
  /* Accordion content styles */
  .accordion-content {
    display: none;
    background-color:rgba(252, 248, 240, 1);
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh;
    /* width: 80vw; */
    /* transition: opacity 0.5s ease-in-out; */
  }
  
  .accordion-item.active .accordion-content {
    display: block;
    background-color: rgba(252, 248, 240, 1);
    /* width: 80vw; */
    opacity: 1;
}
  .accordion-item.active .image-containerPro {
    width: 50px;
    height: 80vh;
}
  .hide{
    display: none;
  }
  .show{
    display: block;
  }
  .projectContainer{
    margin-bottom: 4em;
    
  }
  .projectImgContainer{
    height: 100%;
  }
  .projectDescription{
    font-family: Open Sans;
    font-weight: 400;
    letter-spacing: -0.02em;
    text-align: justify;

  }

  .expandBtnPro{
    height: 2em;
    width: 2em;
    z-index: 10;
} 
  .titleSubPro {
    display: inline; /* Set the display property to inline */
    font-size: 2vw;
    font-family: "Arial";
    font-weight: 500;
  }
  .redBoldSubPro {
  background-color: brown;
  width: 0.7vw;
  height: 0.7vw;
  border-radius: 50px;
  margin-left: 0.4vw;
  margin-bottom: -0.2vw;
}
.titleStylesProjectAcc{
  font-family: Open Sans;
font-size: 32px;
font-weight: 400;
letter-spacing: -0.02em;
line-height: 0.8;
}
.redBoldProjectAcc {
  background-color: brown;
  width: 6.09px;
  height: 6.09px;
  border-radius: 50px;
  margin-left: 0.08vw;
  margin-bottom: 0.1vw;
}
@media (max-width: 768px) {
  .redBoldProjectAcc {
    background-color: brown;
  width: 3.5px;
  height: 3.5px;
  border-radius: 50px;
  margin-left: 0.1vw;
  margin-bottom: -0.5vw;
  }
  .titleStylesProjectAcc{
    color: #000;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
}
