.memberContainer {
  width: 14em;
}
.teamContainer{
  position: fixed;
    z-index: 0;
    top: 12vh;
    left: 74vw;
}
.teamTitleContainer{
  position: fixed;
  top: 40vh;
  margin-top: 1em;
  left: 60vw;
}

.teamCardContainer{
  position: initial;
  z-index: 0;
}
.architechProfile {
  width: 16em;
  height: 19em;
  object-fit: cover;  
}

.titleTeam {
  display: inline; /* Set the display property to inline */
  font-size: 5.5vw;
  font-weight: 700;
  font-family: "Arial";
}
.card{
  display: flex;
  justify-content: center;
}
.imgContainer{
  height: 15em;
  width: 16em;
  overflow: hidden;
  border-top-left-radius: 1.5625em;
  border-top-right-radius: 1.5625em;
}
.nameContainer{
  height: 7em;
  width: 16em;
  background-color: rgba(181, 130, 68, 1);
  color: white;
  border-bottom-left-radius: 1.5625em;
  border-bottom-right-radius: 1.5625em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}
.roleDescription {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: 400;
  margin: 0;
}

.subHead {
  width: 10em;
  font-size: 3em;
  font-weight: 100;
}

.redDot {
  background-color: brown;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50px;
  margin-left: -3vw;
  margin-bottom: -8vw;
}

.subHeadContainer {
  /* margin-top: 4vw; */
  color: #212428;
  transform: rotate(-90deg) translateY(18em) translateX(2.5em);
  transform-origin:left;
  display: block;
}


.subHeadContainerShort {
  /* margin-top: 4vw; */
  color: #212428;
  transform: rotate(-90deg) translateY(6em) translateX(-10em);
  transform-origin:left;
}
.titleStylesTeam{
  color: #000;
font-family: Open Sans;
font-size: 5.1vw;
font-style: normal;
font-weight: 700;
line-height: 1.1;
}
.redBoldTeam {
  background-color: brown;
  width: 1vw;
  height: 1vw;
  border-radius: 50px;
  margin-left: 1vw;
  margin-bottom: -0.5vw;
}
@media (max-width: 768px) {
  .titleStylesTeam{
    color: #000;
    font-family: Open Sans;
    font-size: 8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
  }
  .redBoldTeam {
    background-color: brown;
    width: 1.8vw;
    height: 1.8vw;
    border-radius: 50px;
    margin-left: 1.7vw;
    margin-bottom: -1.4vw;
  }  
}
