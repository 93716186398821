.redBoldPub {
  background-color: brown;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50px;
  margin-left: 0.7vw;
  margin-bottom: -1vw;
}

.titlePub {
  display: inline; /* Set the display property to inline */
  font-size: 9.5vw;
  font-family: "Arial";
  font-weight: 700;
}
