.headerContainer {
  position: sticky;
  width: 100vw;
  z-index: 90;
  top: 0;
  background-color: #E5DED1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(16px); /* Apply a blur effect to the backdrop */
  border: none;
}

/* styles.css or your preferred stylesheet */
.modal-content {
  position: fixed;
  z-index: 100;
  top: 0px;
  border: 1px solid rgba(225, 217, 130, 0.20);
  background: rgba(229, 221, 206, 0.50);
  backdrop-filter: blur(25px);
  padding: 1vw 1vw 3vw 10vw;
  color: rgba(0, 0, 0, 1);
  border-radius: 8px;
  width: 100vw;
  height: 100%;
  border: 0px;
  backdrop-filter: blur(20px); /* Adjust the blur value as needed */
  border: none;
}

.modalClose {
  height: 9vw;
  width: 9vw;
  margin-right: 0vw;
  text-align: right;
}
.modalItem{
  margin-bottom: 0.4em;
    font-size: 0.7em;
}

.noDecoration  {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.3vw;
}
.sideBarIcon{
  
  display: flex;
  justify-content: flex-end;
}
.rotatingLogoContainer {
  position: relative;
  width: 100px; 
}

.circle{
  position: absolute;
  top: 0;
  left: 0;
}

.dots {
  position: absolute;
  top: 16px;
  left: 16px; /* Adjust the opacity as needed for the dots */
}

.rotatingLogoContainer:hover .circle {
  animation: rotateCircle 4s linear infinite; /* Rotate the circle continuously on hover */
}

@keyframes rotateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
