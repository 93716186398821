.partnerElephant {
  width: 18em;
}

.partnerImg2 {
  width: 18em;
}
.partnerImage3 {
  width: 15em;
}

.titlePartner {
  display: inline; /* Set the display property to inline */
  font-size: 5.5vw;
  font-weight: 700;
  font-family: "Arial";
}
.titleStylesPartner{
  color: #000;
font-family: Open Sans;
font-size: 5.1vw;
font-style: normal;
font-weight: 700;
line-height: 1.1;
}
.redBoldPartner {
  background-color: brown;
  width: 1vw;
  height: 1vw;
  border-radius: 50px;
  margin-left: 0.4vw;
  margin-bottom: -0.5vw;
}
@media (max-width: 768px) {
  .titleStylesPartner{
    color: #000;
    font-family: Open Sans;
    font-size: 8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
  }
  .redBoldPartner {
    background-color: brown;
    width: 1.8vw;
    height: 1.8vw;
    border-radius: 50px;
    margin-left: 0.6vw;
    margin-bottom: -1.4vw;
  }  
}