
.innovateContainer p{
  font-size: 1.3rem;
  text-align: justify;
}
.pageContainer{
  padding-left: 5vw;
  padding-right: 5vw;

}
.container {
  padding: 0vw 3vw 0vw 3vw;
}

.innovateArrowContainer {
  display: flex;
  text-align: center;
  justify-content: center;
}

.innvovateArrow {
  width: 6vw;
  height: 10vw;
  margin-top: 33svw;
}

.titleContainer {
  display: flex;
  justify-content: end;
}

.title1,
.title2 {
  display: inline; /* Set the display property to inline */
  font-size: 5.5vw;
  font-weight: 700;
  font-family: "Arial";
  line-height: 1.25em;
}
.titleHome{
  display: inline; /* Set the display property to inline */
  font-size: 9em;
  font-weight: 700;
  font-family: "Arial";
}
.redDotTitleContainer{
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.redDotStyles{
  background-color: brown;
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
}
.commonTitle{
  /* font-size: 4em; */
  font-weight: 700;
  font-family: "Arial";
  padding: 0;
  margin: 0;
  height: fit-content;
  line-height: 1em
}
.redBold {
  background-color: brown;
  width: 1.0vw;
  height: 1.0vw;
  border-radius: 50px;
  margin-left: 0.25vw;
  margin-bottom: -0.5vw;
}

.transparentBold {
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50px;
  margin-bottom: -1vw;
}

.manWithMobile,
.whiteShirtPic {
  width: 30vw;
}

.yellowSareeWoman {
  width: 47vw;
}

.pageMiddleHeader {
  background-color: #212428;
  padding-top: 2vw;
}

.innovateContainer .upperTxt {
  text-align: center;
  font-size: 2rem;
  /* font-style: italic; */
}

.upperTxtContainer{
  display: flex;
  font-size: 3.5vw;
  justify-content: center;
  padding: 0 4vw 4vw 4vw;

}
.uppderTxtContentContainer{
  display: flex;
  background: linear-gradient(182.17deg, rgba(225, 181, 130, 0.2) 1.83%, rgba(225, 181, 130, 0) 113.16%);
  border-radius: 20px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.25);  
  font-size: 3.5vw;
  justify-content: center;
  align-items: center;
  padding: 4vw 4vw 4vw 4vw;
  height: 70vh;
}

.collaborateContentContainer{
  background: linear-gradient(182.17deg, rgba(225, 217, 130, 0.2) 1.83%, rgba(225, 181, 130, 0) 113.16%);
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 4vw 4vw 4vw 4vw;
  font-size: 1em;
  height: fit-content;
}
.collaborateImg{
  text-align: center;
}

.collaborateContainer{  
  font-size: 3.5vw;
  justify-content: center;
  background-image: url('../../assets/homeCollabBackground.png') ;
  background-attachment: fixed;  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: contain;
  padding: 4vw 4vw 4vw 4vw;
}
.homeCardContainer{
  padding: 4vw;
  /* height: 100vh; */
}
.homeCardContentContainer{
  background: linear-gradient(182.17deg, rgba(225, 181, 130, 0.2) 1.83%, rgba(225, 181, 130, 0) 113.16%);
  border-radius: 20px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.25);  
  font-size: 3.5vw;
  justify-content: center;
  padding: 4vw 4vw 4vw 4vw;  
}
.homeCardText{
    display: flex;
    flex-direction: row;
    text-align: justify;
    justify-content: center;
    font-size: 1.2em;
}
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .homeCardText {
      flex-direction: column;
      align-items: center; /* Optional: Center align items horizontally */
  }
}
.homeCardText div{
  text-align: center;
}
.homeCardText h2{
  display: inline; /* Set the display property to inline */
  font-size: 2rem;
  font-weight: 700;
  font-family: "Arial";
  text-align: center;
}
.drawPlantContainer {
  text-align: right;
}

.drawPlant {
  width: 25vw;
}

.coma {
  font-size: 15vw;
  display: inline-block;
  transform: rotate(18deg);
  color: #cacacb;
  margin-top: -3vw;
  margin-left: 2vw;
}

.normalTxt,
.redTxt {
  display: inline;
  font-size: 10vw;
  font-family: "Helvetica";
}

.normalTxt {
  color: #ffffff;
}

.redTxt {
  color: #9e1c31;
}

.headerTitle {
  margin-left: 20vw;
  margin-top: -10vw;
  padding-bottom: 5vw;
}

.discountTitle {
  font-size: 5.2vw;
  font-weight: 700;
}

.discountDetails {
  font-size: 2.5vw;
  margin-top: -2vw;
}

.animPicCommon {
  width: 6em;
}
.imgGrid{
  height: 6rem;
}


.animPic4 {
  margin-left: 8vw;
  margin-top: 5vw;
  width: 25vw;
}
.outerContainer {
  overflow-x: hidden; /* Hide the horizontal scrollbar by default */
  position: relative;
}

.outerContainer:hover {
  overflow-x: auto; /* Show the horizontal scrollbar on hover */
}

.tileContainer {
  display: inline-flex;
  white-space: nowrap;
  margin-bottom: 4vw;
  overflow: hidden; /* Hide any content overflow */
}

.rowContainer {
  display: inline-flex;
  height: 25vw;
  margin-bottom: 3vw;
}

.rowContainer > div {
  width: 25vw;
  height: 100%;
  margin-right: 3vw;
}
/* @media (max-width: 500px) {
  .rowContainer > div {
    width: 40vw;
  }
} */

.outerContainerTop {
  overflow-x: hidden; /* Hide the horizontal scrollbar by default */
  position: relative;
}

.outerContainerTop:hover {
  overflow-x: auto; /* Show the horizontal scrollbar on hover */
}

.tileContainerTop {
  display: inline-flex;
  white-space: nowrap;
  margin-bottom: 2vw;
  overflow: hidden; /* Hide any content overflow */
}

.rowContainerTop {
  display: inline-flex;
  height: 17vw;
  margin-bottom: 3vw;
}

.rowContainerTop > div {
  width: 17vw;
  height: 100%;
  margin-right: 3vw;
}

.style1 {
  background-color: #767c85;
}

.style2 {
  background-color: #e38f28;
  font-size: 3vw;
  font-style: italic;
  color: white;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.style3 {
  background-color: #9e1c31;
}

.tileImg {
  width: 100%;
  height: 100%;
}

.style4 {
  background-color: #a2a2a2;
}

.style5 {
  background-color: #b49e9f;
  font-size: 3vw;
  font-style: italic;
  color: white;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.style6 {
  background-color: #959282;
}

.partnersTitle {
  font-size: 1.8em;
  font-weight: 500;
}

.partnerImg {
  width: 40vw;
  margin-bottom: 5vw;
}

.largImg {
  width: 100%;
  height: 37vw;
}


.partnerImg {
  margin-top: 4vw;
  width: 16em;
}

.partnerImg1 {
  margin-top: 4vw;
  width: 12em;
}

.partnerImg3 {
  margin-top: 4vw;
  width: 16em;
}
.m-scroll:hover{
  animation-play-state: paused;
}

.scroll {
  width: 80vw;
  overflow: hidden;
  z-index: 1;
  margin: auto;
  padding: 0;
}

.m-scroll {
  display: flex;
  gap: 5em;
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  animation: scrollText 50s infinite linear;
  margin: 0;
  width: fit-content;
  align-items: baseline;
}
.m-scroll img{
  margin-right: 2em;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.sliderContainer{
  
  display: flex;
  justify-content: flex-end;
}
.carousel{
  width: 50%;
}
.carousel-root{
  padding: 0 2em 2em 2em;
}
@media (max-width: 639px) {
  .carousel{
    width: 100%;
  }
}
