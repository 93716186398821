.container {
  min-height: 40vw;
}

.titleContainer {
  display: flex;
  padding: 0vw 5vw 2vw 5vw;
}

.titleAbout {
  display: inline; /* Set the display property to inline */
  font-size: 5.5vw;
  font-weight: 700;
  font-family: "Arial";
}

.redBold1 {
  background-color: brown;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50px;
  margin-left: 1.4vw;
  margin-bottom: -1vw;
}

.transparentBold {
  width: 1vw;
  /* margin-bottom: -1vw; */
  height: 1vw;
}

.bodyContentTxt {
  text-align: justify;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);

}

.bodyContent {
  padding: 0vw 6vw 2vw 6vw;
}
.titleStylesAbout{
  color: #000;
font-family: Open Sans;
font-size: 5.1vw;
font-style: normal;
font-weight: 700;
line-height: 1.1;
}
.redBoldAbout {
  background-color: brown;
  width: 1vw;
  height: 1vw;
  border-radius: 50px;
  margin-left: 1.4vw;
  margin-bottom: -0.5vw;
}
@media (max-width: 768px) {
  .titleStylesAbout{
    color: #000;
    font-family: Open Sans;
    font-size: 8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
  }
  .redBoldAbout {
    background-color: brown;
    width: 1.8vw;
    height: 1.8vw;
    border-radius: 50px;
    margin-left: 2.2vw;
    margin-bottom: -1.5vw;
  }  
}

