.titleContainer {
  display: flex;
}
.titleConSpace,
.titleCon {
  display: inline; /* Set the display property to inline */
  font-size: 5.5vw;
  font-weight: 700;
  font-family: "Arial";
}

/* .titleConSpace {
  margin-left: 3vw;
} */



.formContainer {
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Transparent white with an alpha value of 0.5 */
  width: 100%;

  padding: 3vw;
}
.input-container {
  position: relative;
  margin: 2vw;
}

.input-label {
  position: absolute;
  top: 1vw;
  left: 1vw;
  pointer-events: none;
  transition: 0.3s ease-out;
  font-size: 1.2vw;
  color: #999;
}

.input-field {
  width: 40vw;
  padding: 1vw;
  border: 0.1vw solid #999;
  border-radius: 1vw;
  font-size: 1.2vw;
  transition: border-color 0.3s ease-in-out;
}

.input-field:focus + .input-label,
.input-field.has-value + .input-label {
  top: -0vw;
  font-size: 1.4vw;
  color: #333;
  transform: translateY(-100%);
}

.input-field:focus {
  border-color: #333;
}

.input-field2 {
  width: 42vw;
  height: 10vw;
  padding: 1vw 0vw 0vw 1vw;
  border: 0.1vw solid #999;
  border-radius: 1vw;
  font-size: 1.2vw;
  transition: border-color 0.3s ease-in-out;
}

.input-field2:focus + .input-label,
.input-field2.has-value + .input-label {
  top: -0vw;
  font-size: 1.4vw;
  color: #333;
  transform: translateY(-100%);
}

.input-field2:focus {
  border-color: #333;
}

.sendBtn2 {
  padding: 1vw;
  background-color: #008ad2;
  border-radius: 5vw;
  margin-top: 1vw;
  color: white;
  border: 0vw;
  font-weight: 600;
  font-size: 1vw;
  margin-left: 34vw;
}

.contactInfo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  gap: 0.5em;
  font-size: 1.2em;
  
}
.contactUsDescription{
  padding: 1em;
  text-align: justify;
}
.detailsContainer{
  display: flex;
  justify-content: flex-start;
}
.contactField{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: right;
  justify-content: flex-end;
  gap: 0.5em;
  height: 2em;
}
@media (max-width: 768px) {
  .contactInfo{
    font-size: 1em;
    gap:2.5em
  }
}
.titleStylesContact{
  color: #000;
font-family: Open Sans;
font-size: 5.1vw;
font-style: normal;
font-weight: 700;
line-height: 1.1;
}
.redBoldContact {
  background-color: brown;
  width: 1vw;
  height: 1vw;
  border-radius: 50px;
  margin-left: 1.6vw;
  margin-bottom: -0.5vw;
}
@media (max-width: 768px) {
  .titleStylesContact{
    color: #000;
    font-family: Open Sans;
    font-size: 8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
  }
  .redBoldContact {
    background-color: brown;
    width: 1.8vw;
    height: 1.8vw;
    border-radius: 50px;
    margin-left: 2.4vw;
    margin-bottom: -1.5vw;
  }  
}

.toast{
  border: 1px solid rgba(225, 217, 130, 0.20);
  background: rgba(229, 221, 206, 0.50);
  backdrop-filter: blur(50px);
  width: 500px;
}
.subBtn{
  width:  179px;
  height:  42px;
  padding: 5px, 15px, 5px, 15px;
  border-radius: 30px;
  gap: 5px;

}
.subBtnL{
  display: inline-flex;
  height: 37.5px;
  padding: 7.5px 22.5px;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  flex-shrink: 0;
  border-radius: 18.75px;
  background: #9E1C31;
  color: rgba(244, 244, 244, 1);
  position: absolute;
  top: 0;
  z-index: 1;
  margin-left: -1em;
}
.subInput{
  width: 274.5px;
height: 37.5px;
flex-shrink: 0;
border: none;
border-radius: 18.75px 0px 0px 18.75px;
background: #FFF;
}
input:focus{
  border: none;
  outline: none;
}
