.redBoldPro {
  background-color: brown;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50px;
  margin-left: 1.5vw;
  margin-bottom: -1vw;
}

.titlePro {
  display: inline; /* Set the display property to inline */
  font-size: 5.5vw;
  font-family: "Arial";
  font-weight: 700;
}
/* .coverImg{
  width: 5em;
} */
.image {
  flex: 1;
  height: 100%;
  background-position: center;
  background-repeat: none;
  background-size: cover;
  transition: flex 0.8s ease;
  
  &:hover{
    flex: 7;
  }
}

.gallery_wrap{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
}
.titleStylesProject{
  color: #000;
font-family: Open Sans;
font-size: 5.1vw;
font-style: normal;
font-weight: 700;
line-height: 1.1;
}
.redBoldProject {
  background-color: brown;
  width: 1vw;
  height: 1vw;
  border-radius: 50px;
  margin-left: 0.35vw;
  margin-bottom: -0.5vw;
}
@media (max-width: 768px) {
  .titleStylesProject{
    color: #000;
    font-family: Open Sans;
    font-size: 8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
  }
  .redBoldProject {
    background-color: brown;
    width: 1.8vw;
    height: 1.8vw;
    border-radius: 50px;
    margin-left: 0.5vw;
    margin-bottom: -1.4vw;
  }  
}
